<!-- eslint-disable vue/valid-v-model -->
<template>
  <div>
    <div class="modal-header">
      <span class="com-title">{{ title }}</span>
    </div>
    <div id="errorContainer">
      <div v-if="error" class="error">{{ error }}</div>
    </div>
    <div v-if="!loading" class="modal-body">
      <form>
        <div class="form-group">
          <label for="name">Name <span class="required">*</span></label>
          <input
            id="name"
            ref="nameInput"
            v-model="accountData.name"
            type="text"
            aria-describedby="emailHelp"
            @input="validateName"
          />
        </div>
        <div class="form-group">
          <label for="name">Abbreviation <span class="required">*</span></label>
          <input
            id="abbreviation"
            ref="abbInput"
            v-model="accountData.abbreviation"
            type="text"
            @input="validateAbbreviation"
          />
        </div>
        <div class="form-group">
          <label for="name">Prefix <span class="required">*</span></label>
          <input
            id="prefix"
            ref="prefixInput"
            v-model="accountData.prefix"
            type="text"
            @input="validatePrefix"
          />
        </div>
        <div class="form-group">
          <label for="name">Country Code<span class="required">*</span></label>
          <input
            id="countryCode"
            ref="countryCodeInput"
            v-model="accountData.countryCode"
            type="text"
            @input="validateCountryCode"
          />
        </div>
        <div class="form-group">
          <label for="name">Currency <span class="required">*</span></label>
          <input
            id="currency"
            ref="currencyInput"
            v-model="accountData.currency"
            type="text"
            @input="validateCurrency"
          />
        </div>

        <div class="form-group lg-control-box">
          <label for="parentId"> Parent Account </label>
          <k-select
            v-model="accountData.parentId"
            :options="sortedAccounts"
            label-key="name"
            :multiple="false"
            :value="selectedParentId"
            :searchable="true"
            :show-clear-selection-btn="true"
            :disabled="isExternalAdmin"
            label="Please select Parent Account"
            @input="onParentIdSelect"
          />
        </div>
        <div class="form-group form-check">
          <label class="form-check-label" for="userEnabled">Active</label>
          <k-checkbox
            :checked="accountData.active"
            name="accountData.active"
            @change="setActive"
            @selected="setActive"
          />
        </div>
        <div class="form-group form-check">
          <label class="form-check-label" for="colorEnabled">White Label</label>
          <k-checkbox
            id="colorEnabled"
            :checked="whitelabelEnabled"
            name="whitelabelEnabled"
            @change="setColorEnabled"
            @selected="setColorEnabled"
          />
        </div>

        <div v-if="whitelabelEnabled">
          <div class="form-group form-check">
            <label class="form-check-label" for="emailBranding">Apply Email Branding</label>
            <k-checkbox
              id="emailBranding"
              :checked="accountData.organization.emailBranding"
              name="applyEmailBranding"
              @change="setEmailBranding"
              @selected="setEmailBranding"
            />
          </div>
          <div class="form-group color-group">
            <label for="primaryColor">Brand Color</label>
            <label
              for="primaryColor"
              class="color-input-holder"
              @click="showPrimaryColorPicker = !showPrimaryColorPicker"
              >{{ primaryColor }}</label
            >
            <div v-if="showPrimaryColorPicker" class="colorpicker-account">
              <font-awesome-icon
                :icon="['far', 'times']"
                class="colorpicker-close"
                @click="showPrimaryColorPicker = false"
              />
              <chrome-picker
                v-if="showPrimaryColorPicker"
                name="primaryColor"
                :value="primaryColor"
                @input="handleColorChange($event, 'primaryColor')"
              />
            </div>
          </div>

          <div class="form-group color-group overtext">
            <label for="overText">Button Text</label>
            <div class="overtext-dropdown-container">
              <b-select
                :options="buttonTextOptions"
                :selected="buttonText"
                :override-class="'overtext-button'"
                class="b-select-dd"
                :show-tool-tip="false"
                @change="handleOverTextChange"
              />

              <k-flip-button
                :size="3"
                label="BUTTON PREVIEW"
                class="ml-20"
                :container-style="buttonPreviewStyles"
              />
            </div>
          </div>
          <div class="file-form-group">
            <div class="file-group-label">
              Logo
            </div>
            <div class="file-uploader-base">
              <div
                v-if="!logoPreview"
                :class="{
                  dragging: dragging,
                  error: fileError.fileToUpload,
                  marginBottom15: !removeLogo,
                }"
                class="file-uploader-container"
                @drop.capture.prevent.stop="onFileChange($event, 'fileToUpload', 'removeLogo')"
                @dragenter.prevent="dragging = true"
                @dragend.prevent="dragging = false"
                @dragleave.prevent="dragging = false"
                @dragover.prevent
              >
                <label class="file-input-label" for="file-input"
                  ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                  <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                  <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 380 x 60
                  (pixels width by height)<br /><br />
                  PNG/Transparent Background only</label
                >
                <input
                  id="file-input"
                  class="file-input"
                  type="file"
                  name="test"
                  @change="onFileChange($event, 'fileToUpload', 'removeLogo')"
                />
              </div>
              <div v-if="!!fileError.fileToUpload" class="error file-error-msg">
                {{ fileError.fileToUpload }}
              </div>
              <div v-if="!!logoPreview" class="img-preview-container">
                <div class="img-container">
                  <div
                    v-if="!loading"
                    class="cross-icon-container"
                    @click.stop="handleFileReset('fileToUpload', 'removeLogo')"
                  >
                    <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                  </div>
                  <img :src="logoPreview" />
                  <div v-if="uploadPercentage.fileToUpload" class="upload-overlay">
                    <div
                      class="progress"
                      :style="`width: ${uploadPercentage.fileToUpload}%;`"
                    ></div>
                    <div
                      class="rest"
                      :style="`width: ${100 - uploadPercentage.fileToUpload}%;`"
                    ></div>
                    <div class="text">{{ uploadPercentage.fileToUpload }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="removeLogo" class="logo-remove-message">
            You have removed logo file. Please
            <span class="action" @click="undoRemove('removeLogo')">click here</span> to undo
          </div>
          <div class="file-form-group">
            <div class="file-group-label">
              AppConsole Logo
              <p>{{ `(For white backgrounds)` }}</p>
            </div>
            <div class="file-uploader-base">
              <div
                v-if="!appConsoleLogoPreview"
                :class="{
                  dragging: dragging,
                  error: fileError.appConsoleLogoFile,
                  marginBottom15: !removeAppConsole,
                }"
                class="file-uploader-container"
                @drop.capture.prevent.stop="
                  onFileChange($event, 'appConsoleLogoFile', 'removeAppConsole')
                "
                @dragenter.prevent="dragging = true"
                @dragend.prevent="dragging = false"
                @dragleave.prevent="dragging = false"
                @dragover.prevent
              >
                <label class="file-input-label" for="app-console-file"
                  ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                  <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                  <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 300 x 140
                  (pixels width by height)<br /><br />
                  PNG/Transparent Background only</label
                >
                <input
                  id="app-console-file"
                  class="file-input"
                  type="file"
                  name="test"
                  @change="onFileChange($event, 'appConsoleLogoFile', 'removeAppConsole')"
                />
              </div>
              <div v-if="!!fileError.appConsoleLogoFile" class="error file-error-msg">
                {{ fileError.appConsoleLogoFile }}
              </div>
              <div v-if="!!appConsoleLogoPreview" class="img-preview-container">
                <div class="img-container">
                  <div
                    v-if="!loading"
                    class="cross-icon-container"
                    @click.stop="handleFileReset('appConsoleLogoFile', 'removeAppConsole')"
                  >
                    <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                  </div>
                  <img :src="appConsoleLogoPreview" />
                  <div v-if="uploadPercentage.appConsoleLogoFile" class="upload-overlay">
                    <div
                      class="progress"
                      :style="`width: ${uploadPercentage.appConsoleLogoFile}%;`"
                    ></div>
                    <div
                      class="rest"
                      :style="`width: ${100 - uploadPercentage.appConsoleLogoFile}%;`"
                    ></div>
                    <div class="text">{{ uploadPercentage.appConsoleLogoFile }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="removeAppConsole" class="logo-remove-message">
            You have removed logo file. Please
            <span class="action" @click="undoRemove('removeAppConsole')">click here</span> to undo
          </div>
          <div class="file-form-group">
            <div class="file-group-label">
              AppConsole Square Icon
              <p>{{ `(For white backgrounds)` }}</p>
            </div>
            <div class="file-uploader-base">
              <div
                v-if="!appConsoleSquareLogoPreview"
                :class="{
                  dragging: dragging,
                  error: fileError.appConsoleSquareLogoFile,
                  marginBottom15: !removeAppConsoleSquare,
                }"
                class="file-uploader-container"
                @drop.capture.prevent.stop="
                  onFileChange($event, 'appConsoleSquareLogoFile', 'removeAppConsoleSquare')
                "
                @dragenter.prevent="dragging = true"
                @dragend.prevent="dragging = false"
                @dragleave.prevent="dragging = false"
                @dragover.prevent
              >
                <label class="file-input-label" for="app-console-square-file"
                  ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                  <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                  <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 90 x 90
                  (pixels width by height)<br /><br />
                  Square format only PNG/Transparent Background only</label
                >
                <input
                  id="app-console-square-file"
                  class="file-input"
                  type="file"
                  name="test"
                  @change="
                    onFileChange($event, 'appConsoleSquareLogoFile', 'removeAppConsoleSquare')
                  "
                />
              </div>
              <div v-if="!!fileError.appConsoleSquareLogoFile" class="error file-error-msg">
                {{ fileError.appConsoleSquareLogoFile }}
              </div>
              <div v-if="!!appConsoleSquareLogoPreview" class="img-preview-container">
                <div class="img-container">
                  <div
                    v-if="!loading"
                    class="cross-icon-container"
                    @click.stop="
                      handleFileReset('appConsoleSquareLogoFile', 'removeAppConsoleSquare')
                    "
                  >
                    <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                  </div>
                  <img :src="appConsoleSquareLogoPreview" />
                  <div v-if="uploadPercentage.appConsoleSquareLogoFile" class="upload-overlay">
                    <div
                      class="progress"
                      :style="`width: ${uploadPercentage.appConsoleSquareLogoFile}%;`"
                    ></div>
                    <div
                      class="rest"
                      :style="`width: ${100 - uploadPercentage.appConsoleSquareLogoFile}%;`"
                    ></div>
                    <div class="text">{{ uploadPercentage.appConsoleSquareLogoFile }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="removeAppConsoleSquare" class="logo-remove-message">
            You have removed logo file. Please
            <span class="action" @click="undoRemove('removeAppConsoleSquare')">click here</span>
            to undo
          </div>
        </div>
        <div v-if="whitelabelEnabled" class="white-label-domain">
          <label class="form-check-label" for="colorEnabled">Whitelabel Domain</label>
          <k-checkbox
            id="subdomainEnabled"
            :checked="accountData.organization.subdomainEnabled"
            name="subdomainEnabled"
            @change="setBrandingDomain"
            @selected="setBrandingDomain"
          />
        </div>
        <div v-if="accountData.organization.subdomainEnabled" class="file-form-group">
          <div class="file-group-label">
            FavIcon
          </div>
          <div class="file-uploader-base">
            <div
              v-if="!favIconLogoFilePreview"
              :class="{
                dragging: dragging,
                error: fileError.favIconLogoFilePreview,
                marginBottom15: !removeFaviconLogo,
              }"
              class="file-uploader-container"
              @drop.capture.prevent.stop="
                onFileChange($event, 'favIconLogoFile', 'removeFaviconLogo')
              "
              @dragenter.prevent="dragging = true"
              @dragend.prevent="dragging = false"
              @dragleave.prevent="dragging = false"
              @dragover.prevent
            >
              <label class="file-input-label" for="favicon-square-file"
                ><font-awesome-icon :icon="['far', 'cloud-upload-alt']" />&nbsp;&nbsp;
                <span class="browse-txt">Drag and drop here&nbsp;&nbsp;or&nbsp;&nbsp;</span>
                <span class="browse-btn">BROWSE</span><br /><br />Recommended size is 90 x 90
                (pixels width by height)<br /><br />
                Square format only PNG/ICO/Transparent Background only</label
              >
              <input
                id="favicon-square-file"
                class="file-input"
                type="file"
                name="test"
                @change="onFileChange($event, 'favIconLogoFile', 'removeFaviconLogo')"
              />
            </div>
            <div v-if="!!fileError.favIconLogoFile" class="error file-error-msg">
              {{ fileError.favIconLogoFile }}
            </div>
            <div v-if="!!favIconLogoFilePreview" class="img-preview-container">
              <div class="img-container">
                <div
                  v-if="!loading"
                  class="cross-icon-container"
                  @click.stop="handleFileReset('favIconLogoFile', 'removeFaviconLogo')"
                >
                  <font-awesome-icon class="fa-icon-table" :icon="['fal', 'times']" size="1x" />
                </div>
                <img :src="favIconLogoFilePreview" />
                <div v-if="uploadPercentage.favIconLogoFile" class="upload-overlay">
                  <div
                    class="progress"
                    :style="`width: ${uploadPercentage.favIconLogoFile}%;`"
                  ></div>
                  <div
                    class="rest"
                    :style="`width: ${100 - uploadPercentage.favIconLogoFile}%;`"
                  ></div>
                  <div class="text">{{ uploadPercentage.favIconLogoFile }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="removeFaviconLogo && accountData.organization.subdomainEnabled"
          class="logo-remove-message"
        >
          You have removed logo file. Please
          <span class="action" @click="undoRemove('removeFaviconLogo')">click here</span>
          to undo
        </div>
        <div
          v-if="accountData.organization.subdomainEnabled"
          class="form-group whitelabelbrandingContainer"
        >
          <label class="white-label-domain-destination" for="colorEnabled">
            Whitelabel Domain Destination
          </label>
          <div v-if="accountData.organization.subdomainEnabled">
            <div class="white-label-subDomain-container mr-10 mt-20">
              <input
                class="radio-input-btn "
                type="radio"
                :checked="accountDomainOpt === 'accountDomainUrl'"
                @click="handleAccountDomainRadioChange"
              />
              <div
                class="account-domain-url"
                :class="accountDomainOpt !== 'accountDomainUrl' ? 'disabled-input-text' : ''"
              >
                <input
                  id="accountDomainUrl"
                  ref="accountDomainUrlInput"
                  v-model="accountDomainOpt !== 'accountDomainUrl' ? '' : accDomainName"
                  :disabled="accountDomainOpt !== 'accountDomainUrl'"
                  type="text"
                  @input="validateAccountSubdomainUrl"
                  @change="handleAccountDomainUrlChange"
                />
                <label class="form-check-label" for="colorEnabled">
                  .dashboardsandreports.com
                </label>
              </div>
            </div>
            <div class="white-label-subDomain-container">
              <input
                class="radio-input-btn"
                type="radio"
                :checked="accountDomainOpt === 'customDomainUrl'"
                @click="handleCustomDomainRadioChange"
              />
              <input
                id="customDomainUrl"
                ref="customDomainUrlInput"
                v-model="accountDomainOpt !== 'customDomainUrl' ? '' : accDomainName"
                class="custom-domain-input"
                :class="accountDomainOpt !== 'customDomainUrl' ? 'disabled-input-text' : ''"
                :disabled="accountDomainOpt !== 'customDomainUrl'"
                type="text"
                @input="validateAccountCustomUrl"
                @change="handleAccountCustomUrlChange"
              />
            </div>
          </div>
        </div>
        <!-- <div
          v-if="accountData.organization.subdomainEnabled"
          class="form-group application-container"
        >
          <label class="application-text">Application</label>
          <div class="application-dropdown-container">
            <k-select
              ref="applcationOption"
              :options="applicationOptions"
              label-key="application-option"
              :multiple="false"
              :value="applicationOption"
              :searchable="false"
              label="Please select Application"
              @input="handleApplicationOptionChange"
            />
            <b-select
              :options="applicationOptions"
              :selected="applicationOption"
              class="b-select-dd"
              :show-tool-tip="false"
              @change="handleApplicationOptionChange"
            />
          </div>
          <div class="form-group favicon-container">
            <label class="form-check-label margin-right" for="colorEnabled"> Favicon </label>
            <div class="favicon-input-container">
              <b-favicon-input :value="faviconUrl" @on-change="handleFaviconUrlChange" />
              <label class="form-check-label account-domain-label" for="colorEnabled">
                Enter account's domain to pull favicon from, ex. https://bigagency.com
              </label>
            </div>
          </div>
        </div> -->

        <div v-if="false" class="form-group form-check long-label">
          <k-checkbox
            :checked="createAccountOrg"
            name="add.org"
            label="Enable user login?"
            @change="setCreateAccountOrg"
            @selected="setCreateAccountOrg"
          />
        </div>

        <br />
        <div class="form-group  action-footer">
          <div class="actions-btn">
            <k-button :size="3" label="SAVE" class="mr-20" @click="saveAccount" />
            <k-button :size="3" label="CLOSE" @click="close(null)" />
          </div>
        </div>
      </form>
    </div>

    <b-loading-spinner v-if="loading" class="txt-center" />
  </div>
</template>

<script>
import Vue from 'vue';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import accountApi from 'adready-api/api/account';
import organizationApi from 'adready-api/api/organization';
import { get, sync } from 'vuex-pathify';
import { PRIVILEGE_TO_CREATE, E_ORGANIZATION } from 'adready-api/auth-constant';
import { Chrome } from 'vue-color';
import advertiserReportsAPI from '@/api/advertiser-reports';
import filesApi from '@/api/files';
import whiteLabelDomainApi from '@/api/whitelabel-domain-api';
import forklift from '~/components/mixins/forklift-mixin';
import authzMixin from '~/components/mixins/authz-mixin';
import defaultTheme from '~/plugins/Theme/defaultTheme';
import appConfig from '~/config';
import { validateStringWithRegex } from '~/helpers/global/misc-helpers';
import { ACC_FQDN_VALIDATION_REGEX, ACC_SUBDOMAIN_VALIDATION_REGEX } from '~/constant';
import KButton from '../elements/k-button.vue';
import KFlipButton from '../elements/k-flip-button.vue';

// import BFaviconInput from '../elements/b-favicon-input.vue';
import { buildQueryString } from '~/helpers/global/url-helpers';

export default {
  name: 'AddAccount',
  components: {
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
    ChromePicker: Chrome,
    KButton,
    KFlipButton,
    // BFaviconInput,
  },
  mixins: [forklift, authzMixin],
  props: {
    accountId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedParentId: null,
      showPrimaryColorPicker: false,
      name: '',
      createAccountOrg: false,
      errorMsg: null,
      setDefaultData: false,
      isOrgAlert: true,
      E_ORGANIZATION,
      PRIVILEGE_TO_CREATE,
      colorEnabled: false,
      dragging: false,
      whiteLabelDomainData: {
        appType: 'APP',
        domainName: '',
        domainType: '',
      },
      fileToUpload: null,
      appConsoleSquareLogoFile: null,
      favIconLogoFile: null,
      appConsoleLogoFile: null,
      fileError: {},
      uploadPercentage: {},
      loading: false,
      removeLogo: false,
      removeAppConsole: false,
      removeAppConsoleSquare: false,
      removeFaviconLogo: false,
      buttonTextOptions: [
        { id: 'dark', name: 'Dark' },
        { id: 'light', name: 'Light' },
      ],
      applicationOptions: ['FLIP', 'APP'],
    };
  },

  asyncComputed: {
    accountData: {
      default: {
        organization: {
          whitelabelEnabled: false,
          emailBranding: false,
          theme: {},
          subdomainEnabled: false,
          subdomainConfig: {},
        },
        parentId: null,
      },
      async get() {
        if (this.accountId) {
          const accountData = await accountApi.account(this.accountId);
          await this.getWhiteLabelDomainData(accountData);
          return accountData;
        }

        return {
          active: true,
          organization: {
            whitelabelEnabled: false,
            theme: {},
            subdomainEnabled: false,
            subdomainConfig: {},
          },
        };
      },
    },
  },

  computed: {
    userAccounts: get('common/userAccounts'),
    organizations: get('common/allOrganizations'),
    account: sync('common/account'),
    allAccounts: get('common/accounts'),

    /**
     * for Internal Admin return all the accounts other wise return user's accounts.
     */
    allUserAccounts() {
      if (this.isInternalAdmin) {
        return this.allAccounts;
      }
      return this.userAccounts;
    },

    accountDomainOpt: {
      get() {
        if (this.whiteLabelDomainData.domainType === 'FQDN') {
          return 'customDomainUrl';
        }
        return 'accountDomainUrl';
      },
      set(value) {
        this.value = value;
      },
    },
    accDomainName: {
      get() {
        if (this.whiteLabelDomainData.domainName) return this.whiteLabelDomainData.domainName;
        return '';
      },
      set(value) {
        this.value = value;
      },
    },
    error() {
      if (!isBlank(this.errorMsg)) {
        const idElement = document.getElementById('errorContainer');
        if (idElement) {
          idElement.style.display = 'block';
        }
        return this.errorMsg;
      }
      return null;
    },
    title() {
      return this.accountId ? 'Edit Account' : 'New Account';
    },
    primaryColor() {
      return this.accountData?.organization?.theme?.primaryColor || '';
    },
    overText() {
      return this.accountData?.organization?.theme?.buttonText === 'light' ? '#ffffff' : '#303335';
    },
    buttonText() {
      return this.accountData?.organization?.theme?.buttonText || this.buttonTextOptions[0].id;
    },
    applicationOption() {
      return this.whiteLabelDomainData.appType || '';
    },
    whitelabelEnabled() {
      return this.accountData?.organization?.whitelabelEnabled || false;
    },
    subdomainEnabled() {
      return this.accountData?.organization?.subdomainEnabled || false;
    },
    accountDomainUrl() {
      return this.accountData?.organization?.subdomainConfig?.accountDomainUrl || '';
    },
    faviconUrl() {
      return this.accountData?.organization?.subdomainConfig?.faviconUrl || '';
    },
    buttonPreviewStyles() {
      const primaryColor = this.primaryColor || defaultTheme.primaryColor;
      const { overText } = this;

      return `background-color: ${primaryColor}; color: ${overText} !important;`;
    },
    canDragDrop() {
      const div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FileReader' in window
      );
    },
    logoPreview() {
      if (this.fileToUpload?.src) {
        return this.fileToUpload?.src;
      }
      if (this.accountData?.organization?.logoFile?.id && !this.removeLogo) {
        const logoFileId = `${this.accountData?.organization?.logoFile?.id}`;
        return `${appConfig.ADREADY_URL}/api/files/download/${logoFileId}?download=false`;
      }
      return null;
    },
    appConsoleLogoPreview() {
      if (this.appConsoleLogoFile?.src) {
        return this.appConsoleLogoFile?.src;
      }
      if (this.accountData?.organization?.appConsoleLogoFile?.id && !this.removeAppConsole) {
        const logoFileId = `${this.accountData?.organization?.appConsoleLogoFile?.id}`;
        return `${appConfig.ADREADY_URL}/api/files/download/${logoFileId}?download=false`;
      }
      return null;
    },
    appConsoleSquareLogoPreview() {
      if (this.appConsoleSquareLogoFile?.src) {
        return this.appConsoleSquareLogoFile?.src;
      }
      if (
        this.accountData?.organization?.appConsoleSquareLogoFile?.id &&
        !this.removeAppConsoleSquare
      ) {
        const logoFileId = `${this.accountData?.organization?.appConsoleSquareLogoFile?.id}`;
        return `${appConfig.ADREADY_URL}/api/files/download/${logoFileId}?download=false`;
      }
      return null;
    },
    sortedAccounts() {
      const accounts = this.allUserAccounts;
      if (isBlank(accounts)) {
        return [];
      }

      // This Method will return all the account except currently editing account.
      const sortedAccounts = commonHelper.caseInsensitiveSort(
        accounts.filter((account) => account.id !== this.accountData.id),
        'name'
      );
      return sortedAccounts.filter((u) => !isBlank(u.name));
    },
    favIconLogoFilePreview() {
      if (this.favIconLogoFile?.src) {
        return this.favIconLogoFile?.src;
      }
      if (this.accountData?.organization?.favIconLogoFile?.id && !this.removeFaviconLogo) {
        const logoFileId = `${this.accountData?.organization?.favIconLogoFile?.id}`;
        return `${appConfig.ADREADY_URL}/api/files/download/${logoFileId}?download=false`;
      }
      return null;
    },
  },
  methods: {
    onParentIdSelect(parentId) {
      this.selectedParentId = parentId;
    },
    async getWhiteLabelDomainData(accountData) {
      try {
        const data = await whiteLabelDomainApi.get(accountData?.organization?.id);
        this.whiteLabelDomainData = { ...this.whiteLabelDomainData, ...data };
        this.whiteLabelDomainData.dataPresent = true;
      } catch (e) {
        this.whiteLabelDomainData.dataPresent = false;
      }
    },

    handleAccountDomainRadioChange() {
      this.accountDomainOpt = 'accountDomainUrl';
      Vue.set(this.whiteLabelDomainData, 'domainType', 'STD_SUBDOMAIN');
      this.$refs?.customDomainUrlInput?.classList?.remove('errorBox');
    },
    handleCustomDomainRadioChange() {
      this.accountDomainOpt = 'customDomainUrl';
      Vue.set(this.whiteLabelDomainData, 'domainType', 'FQDN');
      this.$refs?.accountDomainUrlInput?.classList?.remove('errorBox');
    },
    handleFaviconUrlChange(val) {
      if (!this.accountData.organization.subdomainConfig) {
        Vue.set(this.accountData.organization, 'subdomainConfig', {});
      }
      if (!this.accountData?.organization?.subdomainConfig?.faviconUrl) {
        Vue.set(this.accountData.organization.subdomainConfig, 'faviconUrl', val);
      }
      this.accountData.organization.subdomainConfig.faviconUrl = val;
    },
    handleAccountDomainUrlChange(val) {
      const v = (val?.srcElement || val?.target).value;

      if (!this.whiteLabelDomainData?.domainName) {
        Vue.set(this.whiteLabelDomainData, 'domainName', v);
        Vue.set(this.whiteLabelDomainData, 'domainType', 'STD_SUBDOMAIN');
      }
      this.whiteLabelDomainData.domainName = v;
    },
    handleAccountCustomUrlChange(val) {
      const v = (val?.srcElement || val?.target).value;
      Vue.set(this.whiteLabelDomainData, 'domainName', v);
      Vue.set(this.whiteLabelDomainData, 'domainType', 'FQDN');
      this.whiteLabelDomainData.domainName = v;
    },
    handleApplicationOptionChange(application) {
      if (!this.whiteLabelDomainData.appType) {
        Vue.set(this.whiteLabelDomainData, 'appType', application);
      }
      this.whiteLabelDomainData.appType = application;
    },
    setBrandingDomain(val) {
      if (!this.accountData.organization) {
        Vue.set(this.accountData, 'organization', {
          subdomainEnabled: false,
          subdomainConfig: {},
        });
      }
      if (!this.accountData.organization?.subdomainConfig) {
        Vue.set(this.accountData.organization, 'subdomainConfig', {});
      }
      if (!this.whiteLabelDomainData.domainName) {
        Vue.set(this.whiteLabelDomainData, 'domainName', this.whiteLabelDomainData.domainName);
      }
      // when user select anable white label domain set app type to APP
      this.handleApplicationOptionChange('APP');
      this.accountData.organization.subdomainEnabled = val.checked;
    },
    handleOverTextChange(color) {
      if (!this.accountData.organization.theme) {
        Vue.set(this.accountData.organization, 'theme', {});
      }
      if (!this.accountData?.organization?.theme?.buttonText) {
        Vue.set(this.accountData.organization.theme, 'buttonText', color);
      }
      this.accountData.organization.theme.buttonText = color;
    },
    undoRemove(removeType) {
      this[removeType] = false;
    },
    uploadProgress(progressEvent, fileType) {
      // eslint-disable-next-line radix
      this.uploadPercentage[fileType] = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    },
    validateFile() {
      if (this.fileError) {
        return false;
      }

      return true;
    },
    validateApplicationSelect() {
      if (
        this.accountData.organization.subdomainEnabled &&
        isBlank(this.whiteLabelDomainData?.appType)
      ) {
        this.errorMsg = 'Please select App Type';
        return false;
      }
      return true;
    },
    handleFileReset(fileType, removeType) {
      if (this[fileType]) {
        this[fileType] = null;
      } else {
        this[removeType] = true;
      }
    },
    onFileChange(ev, fileType, removeType) {
      const validImageTypes =
        fileType === 'favIconLogoFile'
          ? ['image/png', 'image/x-icon', 'image/vnd.microsoft.icon']
          : ['image/png'];
      const files = ev.target.files || (this.canDragDrop ? ev.dataTransfer.files : []);
      if (files.length === 0) {
        return;
      }

      const fileToUpload = files[0];

      if (fileToUpload) {
        if (validImageTypes.includes(fileToUpload?.type)) {
          this.fileError[fileType] = '';
          // handle file upload
          this[fileType] = { file: fileToUpload, src: URL.createObjectURL(fileToUpload) };
          if (ev?.target?.files?.length) {
            ev.target.value = '';
          }
          this[removeType] = false;
        } else {
          this.fileError[fileType] =
            fileType === 'favIconLogoFile'
              ? 'Only png and ICO files are supported'
              : 'Only png files are supported';
        }
      }
      this.fileError = { ...this.fileError };
    },
    handleColorChange(e, key) {
      if (!this.accountData?.organization?.theme) {
        Vue.set(this.accountData.organization, 'theme', {});
      }
      if (!this.accountData?.organization?.theme?.[key]) {
        Vue.set(this.accountData.organization.theme, key, '');
      }
      this.accountData.organization.theme[key] = e.hex;
    },
    setActive(val) {
      this.accountData.active = val.checked;
    },
    setColorEnabled(val) {
      if (!this.accountData.organization) {
        Vue.set(this.accountData, 'organization', { whitelabelEnabled: false, theme: {} });
      }
      if (!this.accountData.organization?.theme) {
        Vue.set(this.accountData.organization, 'theme', {});
      }
      if (!this.accountData.organization?.theme?.buttonText) {
        Vue.set(this.accountData.organization.theme, 'buttonText', this.buttonTextOptions[0].id);
      }
      this.accountData.organization.whitelabelEnabled = val.checked;
      if (!val.checked) {
        this.accountData.organization.subdomainEnabled = false;
      } else if (this.whiteLabelDomainData.dataPresent) {
        this.accountData.organization.subdomainEnabled = true;
      }
    },
    setEmailBranding(val) {
      this.accountData.organization.emailBranding = val.checked;
    },
    setCreateAccountOrg(val) {
      this.createAccountOrg = val.checked;
    },

    async updateOrganization(currentOrganizationId, newOrganizationName, isCreateOrg) {
      let updatedOrg;
      try {
        updatedOrg = await organizationApi.update(currentOrganizationId, {
          name: newOrganizationName,
          loginEnabled: isCreateOrg,
        });
        this.loadAllOrganizations(true);
      } catch (err) {
        this.errorMsg = 'Failed while adding the organization';
        console.error('error add organization api ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
      return updatedOrg;
    },

    clearFormErrors() {
      this.errorMsg = null;
      this.isOrgAlert = true;
      this.$refs.nameInput.classList.remove('errorBox');
      this.$refs.abbInput.classList.remove('errorBox');
      this.$refs.prefixInput.classList.remove('errorBox');
      this.$refs.countryCodeInput.classList.remove('errorBox');
      this.$refs.currencyInput.classList.remove('errorBox');
    },
    validateName() {
      this.clearFormErrors();

      if (isBlank(this.accountData.name)) {
        this.errorMsg = 'Please enter name';
        this.$refs.nameInput.focus();
        this.$refs.nameInput.classList.add('errorBox');
        return false;
      }
      if (!validateStringWithRegex(this.accountData.name)) {
        this.errorMsg = 'Please enter a valid Name';
        this.$refs.nameInput.focus();
        this.$refs.nameInput.classList.add('errorBox');
        return false;
      }
      return true;
    },
    validateAccountCustomUrl(val) {
      const idElement = document.getElementById('errorContainer');
      if (!this.accountData.organization.subdomainEnabled) {
        return true;
      }
      if (this.accountDomainOpt !== 'customDomainUrl') {
        return true;
      }
      const v = (val?.srcElement || val?.target)?.value || this.whiteLabelDomainData.domainName;
      if (isBlank(v)) {
        this.errorMsg = 'Please enter account domain url';
        this.$refs.customDomainUrlInput.focus();
        this.$refs.customDomainUrlInput.classList.add('errorBox');
        idElement.style.display = 'block';
        return false;
      }
      if (!validateStringWithRegex(v, ACC_FQDN_VALIDATION_REGEX)) {
        this.errorMsg = 'Please enter a valid account domain url';
        this.$refs.customDomainUrlInput.focus();
        this.$refs.customDomainUrlInput.classList.add('errorBox');
        idElement.style.display = 'block';
        return false;
      }
      if (idElement) {
        idElement.style.display = 'none';
      }
      this.$refs.customDomainUrlInput.classList.remove('errorBox');
      return true;
    },
    validateAbbreviation() {
      this.clearFormErrors();

      if (isBlank(this.accountData.abbreviation)) {
        this.errorMsg = 'Please enter abbreviation';
        this.$refs.abbInput.focus();
        this.$refs.abbInput.classList.add('errorBox');
        return false;
      }

      if (this.accountData.abbreviation.length !== 3) {
        this.errorMsg = 'Abbreviation must be 3 characters long';
        this.$refs.abbInput.focus();
        this.$refs.abbInput.classList.add('errorBox');
        return false;
      }

      return true;
    },
    validatePrefix() {
      this.clearFormErrors();

      if (isBlank(this.accountData.prefix)) {
        this.errorMsg = 'Please enter prefix';
        this.$refs.prefixInput.focus();
        this.$refs.prefixInput.classList.add('errorBox');
        return false;
      }
      if (this.accountData.prefix.length !== 2) {
        this.errorMsg = 'Prefix must be 2 characters long';
        this.$refs.prefixInput.focus();
        this.$refs.prefixInput.classList.add('errorBox');
        return false;
      }

      return true;
    },
    validateCountryCode() {
      this.clearFormErrors();

      if (isBlank(this.accountData.countryCode)) {
        this.errorMsg = 'Please enter country code';
        this.$refs.countryCodeInput.focus();
        this.$refs.countryCodeInput.classList.add('errorBox');
        return false;
      }
      if (this.accountData.countryCode.length !== 2) {
        this.errorMsg = 'Country code must be 2 characters long';
        this.$refs.countryCodeInput.focus();
        this.$refs.countryCodeInput.classList.add('errorBox');
        return false;
      }

      return true;
    },
    validateCurrency() {
      this.clearFormErrors();

      if (isBlank(this.accountData.currency)) {
        this.errorMsg = 'Please enter currency';
        this.$refs.currencyInput.focus();
        this.$refs.currencyInput.classList.add('errorBox');
        return false;
      }
      if (this.accountData.currency.length !== 3) {
        this.errorMsg = 'Currency must be 3 characters long';
        this.$refs.currencyInput.focus();
        this.$refs.currencyInput.classList.add('errorBox');
        return false;
      }
      return true;
    },
    validateAccountSubdomainUrl(val) {
      const idElement = document.getElementById('errorContainer');
      if (!this.accountData.organization.subdomainEnabled) {
        return true;
      }
      const v = (val?.srcElement || val?.target)?.value || this.whiteLabelDomainData.domainName;
      if (this.accountDomainOpt !== 'accountDomainUrl') {
        return true;
      }
      if (isBlank(v)) {
        this.errorMsg = 'Please enter an account subdomain';
        this.$refs?.accountDomainUrlInput?.focus?.();
        this.$refs?.accountDomainUrlInput?.classList?.add('errorBox');
        idElement.style.display = 'block';
        return false;
      }
      if (!validateStringWithRegex(v, ACC_SUBDOMAIN_VALIDATION_REGEX)) {
        this.errorMsg = 'Please enter a valid account subdomain';
        this.$refs?.accountDomainUrlInput?.focus?.();
        this.$refs?.accountDomainUrlInput?.classList?.add('errorBox');
        idElement.style.display = 'block';
        return false;
      }
      if (idElement) {
        idElement.style.display = 'none';
      }
      this.$refs?.accountDomainUrlInput?.classList?.remove('errorBox');
      return true;
    },
    validateParentId() {
      // You need to implement this validating parent id, for future ref.
      return true;
    },
    async saveAccount() {
      this.accountData.name = this.accountData.name.trim();
      if (
        !this.validateName() ||
        !this.validateAbbreviation() ||
        !this.validatePrefix() ||
        !this.validateCountryCode() ||
        !this.validateCurrency() ||
        !this.validateAccountSubdomainUrl() ||
        !this.validateAccountCustomUrl() ||
        // !this.validateApplicationSelect() ||
        !this.validateParentId()
      ) {
        return this.accountData;
      }
      if (this.whiteLabelDomainData.domainName) {
        const apiResponse = await whiteLabelDomainApi.getDomainName(
          this.whiteLabelDomainData.domainName
        );
        const { data: apiData } = apiResponse;
        if (apiData?.domainName && apiData?.orgId !== this.accountData?.organization?.id) {
          this.errorMsg = 'Domain name already exists';
          if (this.accountDomainOpt === 'customDomainUrl') {
            this.$refs.customDomainUrlInput.focus();
            this.$refs.customDomainUrlInput.classList.add('errorBox');
          } else {
            this.$refs.accountDomainUrlInput.focus();
            this.$refs.accountDomainUrlInput.classList.add('errorBox');
          }
          return false;
        }
      }
      if (!this.accountData.id) {
        let payload = {
          name: this.accountData.name.trim(),
        };
        let param = buildQueryString(payload);
        let response = await advertiserReportsAPI.isNameExist(param);
        const accountFoundWithSameName = response.data;
        if (accountFoundWithSameName) {
          this.errorMsg = `Account already exists with ${this.accountData.name} name`;
          return this.accountData;
        }

        payload = {
          abbreviation: this.accountData.abbreviation,
        };
        param = buildQueryString(payload);
        response = await advertiserReportsAPI.isAbbreviationExist(param);
        const accountFoundWithSameAbbreviation = response.data;
        if (accountFoundWithSameAbbreviation) {
          this.errorMsg = `Account already exists with ${this.accountData.abbreviation} abbreviation`;
          return this.accountData;
        }

        payload = {
          prefix: this.accountData.prefix,
        };
        param = buildQueryString(payload);
        response = await advertiserReportsAPI.isPrefixExist(param);
        const accountFoundWithSamePrefix = response.data;
        if (accountFoundWithSamePrefix) {
          this.errorMsg = `Account already exists with ${this.accountData.prefix} prefix`;
          return this.accountData;
        }
      }

      if (this.accountData.id) {
        this.loading = true;
        if (this.whitelabelEnabled) {
          if (this.fileToUpload) {
            try {
              const formData = new FormData();
              formData.append('file', this.fileToUpload.file);
              formData.append('type', 'ORGANIZATION_LOGO');
              formData.append('organizationId', this.accountData?.organization?.id);
              const config = {
                onUploadProgress: (ev) => this.uploadProgress(ev, 'fileToUpload'),
              };
              const uploadedFile = await filesApi.uploadOrganizationLogo(
                this.accountData?.organization?.id,
                formData,
                config
              );
              this.accountData.organization.logoFile = uploadedFile;
              this.uploadPercentage.fileToUpload = 0;
            } catch (e) {
              console.error(e);
            }
          } else if (this.removeLogo) {
            this.accountData.organization.logoFile = null;
          }
          if (this.appConsoleLogoFile) {
            try {
              const formData = new FormData();
              formData.append('file', this.appConsoleLogoFile.file);
              formData.append('type', 'ORGANIZATION_LOGO');
              formData.append('organizationId', this.accountData?.organization?.id);
              const config = {
                onUploadProgress: (ev) => this.uploadProgress(ev, 'appConsoleLogoFile'),
              };
              const uploadedFile = await filesApi.uploadOrganizationLogo(
                this.accountData?.organization?.id,
                formData,
                config
              );
              this.accountData.organization.appConsoleLogoFile = uploadedFile;
              this.uploadPercentage.appConsoleLogoFile = 0;
            } catch (e) {
              console.error(e);
            }
          } else if (this.removeAppConsole) {
            this.accountData.organization.appConsoleLogoFile = null;
          }
          if (this.favIconLogoFile) {
            try {
              const formData = new FormData();
              formData.append('file', this.favIconLogoFile.file);
              formData.append('type', 'ORGANIZATION_LOGO');
              formData.append('organizationId', this.accountData?.organization?.id);
              const config = {
                onUploadProgress: (ev) => this.uploadProgress(ev, 'favIconLogoFile'),
              };
              const uploadedFile = await filesApi.uploadOrganizationLogo(
                this.accountData?.organization?.id,
                formData,
                config
              );
              this.accountData.organization.favIconLogoFile = uploadedFile;
              this.uploadPercentage.favIconLogoFile = 0;
            } catch (e) {
              console.error(e);
            }
          } else if (
            this.removeFaviconLogo ||
            (!this.accountData.organization.subdomainEnabled &&
              this.whiteLabelDomainData.dataPresent)
          ) {
            this.accountData.organization.favIconLogoFile = null;
          }
          if (this.appConsoleSquareLogoFile) {
            try {
              const formData = new FormData();
              formData.append('file', this.appConsoleSquareLogoFile.file);
              formData.append('type', 'ORGANIZATION_LOGO');
              formData.append('organizationId', this.accountData?.organization?.id);
              const config = {
                onUploadProgress: (ev) => this.uploadProgress(ev, 'appConsoleSquareLogoFile'),
              };
              const uploadedFile = await filesApi.uploadOrganizationLogo(
                this.accountData?.organization?.id,
                formData,
                config
              );
              this.accountData.organization.appConsoleSquareLogoFile = uploadedFile;
              this.uploadPercentage.appConsoleSquareLogoFile = 0;
            } catch (e) {
              console.error(e);
            }
          } else if (this.removeAppConsoleSquare) {
            this.accountData.organization.appConsoleSquareLogoFile = null;
          }
        }
        await accountApi
          .update(this.accountData.id, this.accountData)
          .then(async (updatedAccount) => {
            if (this.account.id === updatedAccount.id) {
              this.account = updatedAccount;
            }
            this.loading = false;
            this.removeLogo = false;
            this.removeAppConsole = false;
            this.removeAppConsoleSquare = false;
            this.removeFaviconLogo = false;
            this.loadAccounts(true);
            this.close(JSON.parse(JSON.stringify(updatedAccount)));
            return updatedAccount;
          })
          .catch((error) => {
            this.loadingMessage = '';
            this.loading = false;
            this.removeLogo = false;
            this.removeAppConsole = false;
            this.removeAppConsoleSquare = false;
            this.removeFaviconLogo = false;
            this.forkisBlankliftErrHandler(error, 'updatedAccount');
            this.errorMsg = 'Failed to update Account. Try again or contact support';
          });
      } else {
        this.loading = true;
        await accountApi
          .create(this.accountData)
          .then(async (createdAccount) => {
            if (createdAccount?.organization?.id && this.whitelabelEnabled) {
              if (this.fileToUpload) {
                try {
                  const formData = new FormData();
                  formData.append('file', this.fileToUpload.file);
                  formData.append('type', 'ORGANIZATION_LOGO');
                  formData.append('organizationId', createdAccount?.organization?.id);
                  const config = {
                    onUploadProgress: (ev) => this.uploadProgress(ev, 'fileToUpload'),
                  };
                  const uploadedFile = await filesApi.uploadOrganizationLogo(
                    createdAccount?.organization?.id,
                    formData,
                    config
                  );
                  createdAccount.organization.logoFile = uploadedFile;
                  this.uploadPercentage = {};
                  this.loadingMessage = '';
                } catch (e) {
                  console.error(e);
                  this.loadingMessage = '';
                }
              }
              if (this.appConsoleLogoFile) {
                try {
                  const formData = new FormData();
                  formData.append('file', this.appConsoleLogoFile.file);
                  formData.append('type', 'ORGANIZATION_LOGO');
                  formData.append('organizationId', createdAccount?.organization?.id);
                  const config = {
                    onUploadProgress: (ev) => this.uploadProgress(ev, 'appConsoleLogoFile'),
                  };
                  const uploadedFile = await filesApi.uploadOrganizationLogo(
                    createdAccount?.organization?.id,
                    formData,
                    config
                  );
                  createdAccount.organization.appConsoleLogoFile = uploadedFile;
                  this.uploadPercentage = {};
                  this.loadingMessage = '';
                } catch (e) {
                  console.error(e);
                  this.loadingMessage = '';
                }
              }
              if (this.appConsoleSquareLogoFile) {
                try {
                  const formData = new FormData();
                  formData.append('file', this.appConsoleSquareLogoFile.file);
                  formData.append('type', 'ORGANIZATION_LOGO');
                  formData.append('organizationId', createdAccount?.organization?.id);
                  const config = {
                    onUploadProgress: (ev) => this.uploadProgress(ev, 'appConsoleSquareLogoFile'),
                  };
                  const uploadedFile = await filesApi.uploadOrganizationLogo(
                    createdAccount?.organization?.id,
                    formData,
                    config
                  );
                  createdAccount.organization.appConsoleSquareLogoFile = uploadedFile;
                  this.uploadPercentage = {};
                  this.loadingMessage = '';
                } catch (e) {
                  console.error(e);
                  this.loadingMessage = '';
                }
              }
              await organizationApi.update(
                createdAccount.organization.id,
                createdAccount.organization
              );
            }
            this.loadAccounts(true);
            this.close(JSON.parse(JSON.stringify(createdAccount)));
            return createdAccount;
          })
          .catch((error) => {
            this.loadingMessage = '';
            this.forkisBlankliftErrHandler(error, 'createdAccount');
            this.errorMsg = 'Failed to add Account. Try again or contact support';
          });
      }
      if (
        !this.accountData.organization.subdomainEnabled &&
        this.whiteLabelDomainData.dataPresent
      ) {
        await whiteLabelDomainApi.delete(this.accountData?.organization?.id);
      }
      if (this.accountData.organization.subdomainEnabled) {
        const data = {
          orgId: this.accountData?.organization?.id,
          appType: this.whiteLabelDomainData?.appType,
          domainName: this.whiteLabelDomainData.domainName,
          domainType: this.whiteLabelDomainData.domainType,
          apiDomain: this.whiteLabelDomainData.apiDomain,
        };
        if (!this.whiteLabelDomainData.dataPresent) {
          await whiteLabelDomainApi.create(data);
        } else {
          await whiteLabelDomainApi.update(
            { orgId: this.accountData?.organization?.id, acctId: this.accountData.id },
            data
          );
        }
      }
      return null;
    },
    close(ev) {
      this.$emit('close', ev);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper-box .form-group .dropdown.b-select-dd {
  min-width: 210px !important;
}
.disabled-input-text {
  opacity: 0.4;
}
.mr-10 {
  margin-bottom: 10px;
}
::v-deep .vc-chrome-toggle-btn {
  display: none !important;
}
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}

.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);
  .modal-header {
    margin-bottom: 10px;
    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }
    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }
  .modal-body {
    width: 600px;
  }
  .application-text {
    width: 31% !important;
    text-align: left;
  }
  .white-label-domain {
    display: flex;
    align-items: center;
    flex-direction: row;
    .form-check-label {
      width: 27.5%;
      text-align: left;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      padding: 9px;
    }
    .checkbox-btn {
      margin-left: 20px;
    }
  }
  .form-group {
    &.application-container {
      overflow: visible;
      .application-dropdown-container {
        //text-align: left;
        ::v-deep .dropdown {
          // min-width: auto !important;
          // width: 35%;
          // margin-right: 20px !important;
          .dropdown-menu {
            position: absolute !important;
          }
        }
      }
    }
    &.favicon-container {
      display: flex;
      align-items: center;
      .favicon-input-container {
        width: 100%;
      }
      .form-check-label {
        width: unset;

        &.margin-right {
          margin-right: 114px;
        }
        &.account-domain-label {
          font-size: 12px;
          padding: unset;
          padding-left: 3px;
        }
      }
    }

    &.whitelabelbrandingContainer {
      display: flex;
      align-items: center;
      .white-label-domain-destination {
        width: 31%;
        text-align: left;
      }
      .white-label-subDomain-container {
        display: flex;
        align-items: center;
        .form-check-label {
          width: 100%;
        }
        &.mt-20 {
          margin-top: 20px;
        }
        .modal-body .form-input {
          margin-bottom: 0px;
        }
      }
    }
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;
    &.favicon-container {
      display: flex;
      align-items: center;
      .favicon-input-container {
        width: 100%;
      }
      .form-check-label {
        width: unset;

        &.margin-right {
          margin-right: 114px;
        }
        &.account-domain-label {
          font-size: 12px;
          padding: unset;
          padding-left: 3px;
        }
      }
    }
    &.whitelabelbrandingContainer {
      display: flex;
      align-items: center;

      .white-label-subDomain-container {
        display: flex;
        .form-check-label {
          width: 100%;
        }
        .modal-body .form-input {
          margin-bottom: 0px;
        }
      }
      .white-label-domain {
        display: flex;
        align-items: center;
        flex-direction: row;
        .form-check-label {
          width: 51%;
        }

        .checkbox-btn {
          margin-left: 20px;
        }
      }
    }
    &.width-50 {
      width: 50%;
      display: inline-block;
      label {
        width: 40%;
      }
      input {
        width: 60%;
      }
    }
    &.color-group {
      &::v-deep .vc-chrome {
        float: right;
      }
      &.overtext {
        overflow: visible;
        .overtext-dropdown-container {
          text-align: left;
          ::v-deep .dropdown {
            min-width: auto !important;
            width: 35%;
            margin-right: 20px !important;
            .dropdown-menu {
              position: absolute !important;
            }
            .overtext-button {
              // background-color: #323239 !important;
              border: 1px solid rgba(170, 170, 170, 0.1) !important;
              .svg-inline--fa {
                color: #cad1d6 !important;
              }
            }
            .dropdown-menu li {
              color: #cad1d6 !important;
            }
            .dropdown-menu {
              background-color: #323239 !important;
            }
            .selected-opt {
              color: #cad1d6 !important;
            }
          }
        }
      }
      .color-input-holder {
        width: 35%;
        float: left;
        height: 30px;
        font-size: 0.875rem;
        display: block;
        min-height: 40px;
        padding: 8px 10px;
        color: #cad1d6;
        background-color: #323239;
        cursor: pointer;
        border-radius: 4px;
        border: 1px solid rgba(170, 170, 170, 0.1);
      }
      input {
        border: none;
        height: 0;
        width: 0;
        overflow: hidden;
        padding: 0;
        &:hover {
          border: none;
        }
      }
    }
    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }
    .list-url {
      clear: both;
      width: 80%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;
      p {
        text-align: left;
      }
      a {
        display: inline;
        color: #848890;
      }
      span {
        font-size: 10px;
        top: -6px;
        color: #ff0202;
        left: 3px;
        position: relative;
      }
    }
    label {
      width: 20%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      padding: 9px;
      line-height: 20px;
    }
    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }
    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;
      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }
    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}

::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.actions-btn {
  margin-bottom: 20px;
}
::v-deep .checkbox-btn {
  margin-left: 122px;
  span {
    font-size: 14px;
    color: #cad1d6;
    letter-spacing: 0.07em;
  }
  svg {
    width: 18px;
    height: 18px;
    &.checkbox-inactive {
      color: #cad1d6;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      background-color: #323239;
      path {
        fill: #323239;
      }
    }
  }
}
.lg-control-box {
  display: inline-flex;
  width: 100%;
  label {
    width: 24% !important;
  }
}
::v-deep .form-input-wrap {
  height: 42px;
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
}

::v-deep .modal-body .form-input .form-input-field.v-select {
  height: 40px;
  text-transform: capitalize !important;
  cursor: pointer !important;
  background-color: #323239 !important;
  border: 1px solid rgba(170, 170, 170, 0.1) !important;
  border-radius: 4px !important;
  color: #cad1d6 !important;
  font-size: 14px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__dropdown-toggle {
  background-color: #323239 !important;
  min-height: 38px !important;
}
::v-deep .form-input .form-input-field.v-select .vs__search {
  padding: 4px 6px;
  color: #cad1d6;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
::v-deep
  .form-input
  .form-input-field.v-select
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  color: #cad1d6;
  font-size: 14px;
}
::v-deep .form-input-wrap .form-click .action-icons svg,
::v-deep .form-input-wrap .form-click .selected-text {
  font-size: 14px;
}
::v-deep
  .form-input
  .form-input-field.v-select.vs--open
  .vs__dropdown-toggle
  .vs__actions
  .action-icons
  svg {
  transform: rotate(-180deg);
  font-size: 14px;
}
.mr-20 {
  margin-right: 20px;
}
.form-input-wrap .form-click .action-icons {
  padding: 0px !important;
}
.file-form-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .file-group-label {
    width: 31%;
    display: inline-block;
    font-size: 0.875rem;
    color: #cad1d6;
    letter-spacing: 0.07em;
    padding: 9px;
    line-height: 20px;
  }
  .file-uploader-base {
    flex: 1;
  }
  .file-uploader-container {
    border: 2px dashed #888;
    flex: 1;
    &.error {
      border-color: #ff0202;
    }
    &.dragging {
      border: 1px dashed #fff;
      color: #fff;
    }
    &.marginBottom15 {
      margin-bottom: 15px;
    }
    .file-input {
      display: none;
    }
    .file-input-label {
      padding: 53px 10px;
      display: block;
      cursor: pointer;
    }
  }
  .file-error-msg {
    text-align: left;
  }
}
.img-preview-container {
  text-align: left;
  margin-top: 20px;
  .img-container {
    height: 60px;
    display: inline-block;
    position: relative;
    background-color: #fff;
    .cross-icon-container {
      width: 15px;
      height: 15px;
      position: absolute;
      top: -7px;
      right: -7px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
    }
    img {
      height: 100%;
      width: auto;
    }
    .upload-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      flex-direction: row;
      .progress {
        height: 100%;
        background-color: rgba(256, 256, 256, 0.8);
      }
      .rest {
        height: 100%;
        background-color: rgba(256, 256, 256, 0.2);
      }
      .text {
        font-size: 16px;
        color: #000;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.action-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.loading-message {
  font-style: italic;
  font-size: 14px;
  color: #cad1d6;
  flex: 1;
  text-align: left;
  .upload-percentage {
    margin-left: 4px;
    font-weight: 700;
  }
}

.logo-remove-message {
  padding-left: 30%;
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 15px;
  .action {
    font-weight: 900;
    cursor: pointer;
  }
}
.browse-txt {
  font-weight: 600;
  font-size: 14px;
}
.browse-btn {
  padding: 9px 19px;
  line-height: 22px;
  background-color: var(--adminprimarycolor);
  font-size: 12px;
  font-weight: 600;
  color: var(--adminovertext) !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  cursor: pointer;
  border-radius: 3px;
  margin: 0 5px;
}
.colorpicker-account {
  position: relative;
}
.colorpicker-account .vc-chrome {
  padding-top: 20px;
  position: relative;
  display: block;
}
.colorpicker-account .colorpicker-close {
  right: 9px;
  position: absolute;
  z-index: 2;
  color: #4a4a4a;
  top: 3px;
  width: 10px;
  cursor: pointer;
}
.account-domain-url {
  display: flex;
  align-items: 'center';
}
.application-dropdown-container {
  display: flex;
  width: 250px !important;
}
.radio-input-btn {
  margin-right: 15px;
  width: 4% !important;
  height: 20px !important;
  align-items: center;
  display: flex;
}
</style>
